@charset "UTF-8";
// ==========================================================================
// Meal
// ==========================================================================
.mealMain {
  padding-bottom: 30px;
  &_content {
    &.foodList {
      width: 100%;
      padding: 0 7.2%;
      .foodList {
        &_item {
          display: flex;
          align-content: center;
          align-items: center;
          width: 100%;
          padding: 1em 0;
          border-bottom: 1px solid #888;
          &:last-child {
            border: 0; }
          &_name {
            @include pxrem(1.8);
            padding-left: 1em; } } } } } }

.mealPopup {
  margin-bottom: 30px;
  &-1 {
    padding: 0 7.2%;
    &_title {
      @include pxrem(2.5);
      margin: 30px auto 30px;
      border-radius: 15px;
      background-color: colors(mono, w);
      font-weight: normal;
      text-align: center; }
    &_radio {
      @include pxrem(1.8);
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      input[type="radio"] {
        margin: 0; } }
    &_time {
      @include pxrem(1.8);
      margin-top: 1em;
      text-align: center;
      font-weight: bold; }
    &_image {
      @include pxrem(1.8);
      margin-top: 1em;
      text-align: center;
      &_info {
        margin: .5em 0 1em; } }
    &_amount {
      padding: 0;
      list-style: none;
      text-align: center;
      li {
        width: 100%;
        margin-bottom: 1em; } } }

  &-2 {
    padding: 0 7.2%;
    &_title {
      @include pxrem(1.8);
      font-weight: normal;
      text-align: center; }
    &_body {
      width: 100%;
      margin-bottom: 30px;
      &_item {
        @include pxrem(1.8);
        display: flex;
        align-content: center;
        align-items: center;
        padding: 1em 0;
        width: 100%;
        border-bottom: 1px solid #888;
        &:last-child {
          border: 0; }
        &_name {
          @include pxrem(1.8);
          padding-left: 1em; } } } }
  &-3 {
    padding: 0 7.2%;
    &_title {
      @include pxrem(1.8);
      font-weight: normal;
      text-align: center; }
    &_body {
      @include pxrem(1.8);
      width: 100%;
      margin-bottom: 30px;
      text-align: center; } } }
