@charset "UTF-8";
// ==========================================================================
// Border
// ==========================================================================
hr {
  display: block;
  margin: 10px 0;
  height: 7px;
  border: 0;
  background: url('/images/sp/common/border_dots.png') repeat-x; }
