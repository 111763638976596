@charset "UTF-8";
// ==========================================================================
// Top
// ==========================================================================
.top {
  padding-top: 2em;
  background-color: colors(primary);
  &_title {
    padding: 1em 0;
    margin: 0;
    text-align: center; }
  &_news {
    .news {
      &_title {
        @include pxrem(1.8);
        margin: 0 1rem;
        padding: .5em 0;
        background-color: colors(secondary);
        text-align: center; }
      &_body {
        margin: 0 1rem;
        background-color: colors(secondary, light);
        dl {
          overflow-y: scroll;
          max-height: 10em;
          margin: 0 0 2em;
          padding: 2em 1em; }
        dt {
          @include pxrem(1.8); }
        dd {
          @include pxrem(1.6);
          margin: 0; } } } }
  &_contents {
    @include pxrem(1.4);
    margin: .5em 1rem 2em;
    padding: 2em 1em;
    background-color: colors(secondary, light);
    text-align: justify;
    text-justify: inter-ideograph; }
  &_enter {
    padding-bottom: 2em;
    text-align: center;
    a {
      display: block;
      margin-top: 1em; } } }
