@charset "UTF-8";
// ==========================================================================
// Main
// ==========================================================================
.btn {
  appearance: none;
  margin-right: 22px;
  padding: 0;
  border: 0;
  outline: 0;
  user-select: none;
  text-decoration: none;
  text-align: center;
  &-text {
    border: 2px solid colors(secondary, border);
    border-radius: 20px;
    background-color: colors(secondary, dark);
    color: colors(mono, w); }
  &-middle {
    @include pxrem(2);
    width: 70%;
    padding: .8em 0;
    line-height: 1; }
  &-small {
    @include pxrem(2);
    width: 50%;
    padding: 17px 0;
    line-height: 1; }
  &-expand {
    display: block;
    width: 100%; }
  &-block {
    display: block; }
  &-center {
    margin: auto; } }

.btnGroup {
  display: flex;
  padding: 10px;
  > * {
    flex-grow: 1; }
  &-center {
    text-align: center; }
  &-right {
    text-align: right; }
  &-mtLarge {
    margin-top: 90px; }
  &-mtMedium {
    margin-top: 60px; }
  &-mtSmall {
    margin-top: 25px; } }
