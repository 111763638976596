@charset "UTF-8";
// ==========================================================================
// Mypage
// ==========================================================================
.masterLevel {
  margin-bottom: 2em;
  &_link {
    @include pxrem(1.8);
    width: 100%;
    margin: 0 .5em;
    text-align: right; }
  &_title {
    @include pxrem(2);
    position: relative;
    margin: 1em .5em 0;
    padding: .8em 1em;
    border-radius: 15px;
    background-color: colors(secondary);
    color: colors(mono, k);
    text-align: justify;
    text-justify: inter-ideograph;
    font-weight: bold;
    line-height: 1.7;
    text-align: center;
    &:before {
      position: absolute;
      bottom: -48px;
      left: 50%;
      margin-left: -14px;
      content: ' ';
      height: 24px;
      width: 24px;
      border-top: 24px solid colors(secondary);
      border-right: 14px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 14px solid transparent; } }
  &_image {
    text-align: center; }
  &-first {
    text-align: center; } }

.score {
  margin: 2em 1em;
  &_grid {
    display: flex;
    align-items: stretch; }
  &_title {
    @include pxrem(1.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0;
    padding: 10px;
    border-radius: 15px 0 0 15px;
    background-color: colors(mono, w);
    text-align: center;
    &_date {
      @include pxrem(1.3);
      color: colors(secondary, dark); } }
  &_num {
    @include pxrem(5.8);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0;
    padding: 10px;
    border-radius: 0 15px 15px 0;
    color: colors(mono, w);
    background-color: colors(secondary, dark);
    text-align: center;
    &_denom {
      @include pxrem(1.6);
      position: absolute;
      right: 10px;
      bottom: 0; } } }

.userAnalysis {
  margin-bottom: 2em;
  &_title {
    @extend %heading; }
  &_item {
    margin: 0 1em;
    &_title {
      @include pxrem(1.6);
      margin: 0; } } }

.mealTable {
  border-radius: 15px;
  background-color: colors(mono, w);
  &_title {
    @include pxrem(3);
    display: inline-block;
    margin: 0;
    padding: 15px 10px 0;
    font-weight: bold;
    line-height: 1;
    &-breakfast {
      @extend .mealTable_title;
      color: #ff6f9a; }
    &-lunch {
      @extend .mealTable_title;
      color: #ff8702; }
    &-dinner {
      @extend .mealTable_title;
      color: #4c9ad8; }
    &-snack {
      @extend .mealTable_title;
      color: #999; } }
  &_calorie {
    @include pxrem(1.6);
    display: inline-block;
    margin: 0;
    font-weight: bold; }
  &_meals {
    @include pxrem(1.3);
    margin: 0;
    padding: 5px 10px 10px 10px;
    border-bottom: 1px dashed colors(secondary);
    &:last-child {
      border: 0; } } }

.balance {
  margin-bottom: 2em;
  &_title {
    @extend %heading; }
  &_type {
    margin: 10px;
    &_item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; } }
  .generalComment {
    margin: 10px; } }

.radarChart {
  margin: 10px;
  border-radius: 15px;
  background-color: colors(mono, w); }

.pieChart {
  margin: 10px;
  border-radius: 15px;
  background-color: colors(mono, w); }

.rhythm {
  &_title {
    @extend %heading; }
  .generalComment {
    margin: 10px; } }


.pill {
  display: flex;
  margin: 1em 0;
  &_title {
    @include pxrem(1.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0;
    padding: 1em;
    border-radius: 10px 0 0 10px;
    background-color: colors(secondary);
    white-space: nowrap;
    text-align: center;
    line-height: 1; }
  &_body {
    @include pxrem(1.6);
    flex-grow: 2;
    margin: 0;
    padding: 1em .5em;
    border-radius: 0 10px 10px 0;
    background-color: colors(mono, w);
    font-weight: bold;
    line-height: 1;
    &_anotation {
      @include pxrem(1.3);
      display: block;
      margin-top: .5em;
      color: colors(warning, dark);
      font-weight: normal;
      line-height: 1.2;
      em {
        font-weight: bold;
        text-decoration: none;
        font-style: normal; } } } }

.generalComment {
  @include pxrem(1.6);
  padding: .8em 1em;
  border: 2px solid colors(secondary);
  border-radius: 15px;
  background-color: colors(secondary, exlight);
  font-weight: bold;
  text-align: justify;
  text-justify: inter-ideograph;
  line-height: 1.5; }


/* ----Okada Start---- */
.resultcommentary_title {
  font-size:  1.4em;
  position: relative;
  height: 43px;
  margin: 0 0 15px 0;
  background-color: #facd02;
  color: #3e3a39;
  text-align: center;
  line-height: 46px; }

.resultcommentary_subtitle {
  border-color: #ffc400;
  border-style: solid;
  border-width: 2px 0 2px;
  background-color: #fff1c5;
  text-align: center;
  font-size:  1.3em;
  padding: 6px;
  margin: 0;
  line-height: 1.4; }

.resultcommentary_box {
  border-color: #ffc400;
  border-style: solid;
  border-width: 0 0 2px;
  background-color: #fff;
  margin-bottom: 15px;
  padding: 10px 5px; }

.resultcommentary_bg_1 {
  background: #fff url('/images/sp/result/resultcommentary01.jpg') no-repeat 615px 40px; }

.resultcommentary_bg_2 {
  background: #fff url('/images/sp/result/resultcommentary02.jpg') no-repeat 635px 50px; }

.resultcommentary_bg_3 {
  background: #fff url('/images/sp/result/resultcommentary03.jpg') no-repeat 580px 45px; }

.resultcommentary_box p {
  font-weight: bold;
  font-size:  1.1em;
  margin: 0 10px 15px;
  line-height: 1.5; }

.resultcommentary_box .small {
  font-size:  1em;
  font-weight: normal; }

.resultcommentary_caption {
  text-align: center;
  padding: 3px;
  margin: 0 0 15px;
  font-size:  1.3em;
  background-color: #facd02;
  border-radius: 10px; }

.resultcommentary_formula {
  border: solid 2px #ffc400;
  background-color: #fffde5;
  text-align: center;
  padding: 10px 5px;
  border-radius: 10px; }

.resultcommentary_comment {
  margin: 0 auto; }

.resultcommentary_comment::after {
  content: '';
  display: block;
  clear: both; }

.resultcommentary_comment_body {
  position: relative;
  float: left;
  height: 170px;
  width: 640px;
  margin: 0 24px 0 0;
  padding: .8em 1em;
  border: 2px solid #facd02;
  border-radius: 15px;
  background-color: #fffde5; }

.resultcommentary_comment p {
  font-size:  1.1em;
  font-weight: bold; }

.resultcommentary_comment_body:before {
  position: absolute;
  right: -48px;
  top: 70px;
  content: ' ';
  height: 24px;
  width: 24px;
  border-top: 14px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #facd02; }

.resultcommentary_comment_body:after {
  position: absolute;
  right: -44px;
  top: 70px;
  content: ' ';
  height: 24px;
  width: 24px;
  border-top: 14px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #fffde5; }

.resultcommentary_comment img {
  margin: 13px 0 0 20px; }

.resultcommentary_comment_s {
  width: 808px;
  margin: 35px auto; }

.resultcommentary_comment_s::after {
  content: '';
  display: block;
  clear: both; }

.resultcommentary_comment_body_s {
  position: relative;
  float: left;
  height: 123px;
  width: 640px;
  margin: 0 24px 0 0;
  padding: 2em 1em;
  border: 2px solid #facd02;
  border-radius: 15px;
  background-color: #fffde5; }

.resultcommentary_comment_s p {
  font-size:  1.1em;
  font-weight: bold; }

.resultcommentary_comment_body_s:before {
  position: absolute;
  right: -48px;
  top: 46px;
  content: ' ';
  height: 24px;
  width: 24px;
  border-top: 14px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #facd02; }

.resultcommentary_comment_body_s:after {
  position: absolute;
  right: -44px;
  top: 46px;
  content: ' ';
  height: 24px;
  width: 24px;
  border-top: 14px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #fffde5; }

.resultcommentary_comment_s img {
  margin: -10px 0 0 20px; }

.resultcommentary_text {
  margin: 0 15px 15px; }

.resultcommentary_text p {
  font-weight: bold;
  font-size:  1.1em;
  line-height: 1.5; }

.resultcommentary_text .small {
  font-weight: normal;
  font-size:  1em; }

.resultcommentary_caption_check {
  margin: 0 10px 5px 10px;
  padding: 7px 0 6px 45px;
  font-size:  1.1em;
  line-height: 1.5;
  background: url('/images/sp/result/point_check.png') no-repeat center left;
  background-size: 35px; }

.resultcommentary_list_pin {
  list-style: none;
  margin: 0 15px 5px 25px;
  padding: 0; }

.resultcommentary_list_pin li {
  font-size:  1em;
  padding: 0 0 5px 30px;
  background: url('/images/sp/result/point_pin.png') no-repeat 0 3px;
  background-size: 15px; }

.resultcommentary_caption_pin {
  font-size:  1.2em;
  padding: 0px 0 0px 35px;
  margin: 0 0 5px 0;
  background: url('/images/sp/result/point_pin.png') no-repeat 10px 6px;
  background-size: 15px; }

p.resultcommentary_comment {
  font-weight: normal;
  font-size:  1em;
  background-color: #fff7e2;
  border-radius: 10px;
  padding: 10px 15px; }

.generalComment_small {
  width: 95%;
  font-size:  1em;
  margin: 0 auto 20px;
  padding: .8em 1em;
  border: 2px solid #facd02;
  border-radius: 15px;
  background-color: #fffde5;
  font-weight: bold;
  line-height: 1.5;
  word-break: break-all; }

.generalComment_small a {
  font-weight: normal; }

.tbl-m,
.tbl-s {
  border: solid 2px #ffc400;
  margin: 0 auto 20px;
  border-collapse: collapse;
  width: 100%; }

.tbl-m th,
.tbl-s th {
  border: solid 1px #ffc400;
  background-color: #fff1c5;
  font-size:  1em;
  padding: 0; }

.tbl-m td,
.tbl-s td {
  border-color: #ccc;
  border-style: solid dotted;
  border-width: 1px;
  font-size:  1em;
  text-align: center;
  padding: 1px 0; }

.tr-o tr:nth-child(odd),
.tr-e tr:nth-child(even) {
  background-color: #eee; }

.tr-o tr:nth-child(even),
.tr-e tr:nth-child(odd) {
  background-color: #fff; }

.tbl-l {
  width: 98%;
  border: solid 2px #ffc400;
  margin: 0 auto 10px;
  border-collapse: separate;
  border-radius: 15px;
  border-spacing: initial; }

.tbl-l th {
  background-color: #ffc400;
  text-align: center;
  width: 65px; }

.tbl-l th,
.tbl-l td {
  padding: 5px 10px;
  font-size:  1em; }

.tbl-l-t {
  border-radius: 10px 0 0 0;
  border-bottom: 2px dotted #fff; }

.tbl-l-td {
  border-bottom: 2px dotted #ffc400; }

.tbl-l-b {
  border-radius: 0 0 0 10px; }

.tbl-l-m {
  border-radius: 10px 0 0 10px; }

.u-mb-n {
  margin-bottom: 10px; }
/*----Okada End----*/
