@charset "UTF-8";
// ==========================================================================
// Dropdown
// ==========================================================================
.dropdown {
  position: relative;
  margin: 0;
  font-weight: bold;
  cursor: pointer;
  &_title {
    @include pxrem(1.8);
    margin: 0;
    border: 1px solid colors(secondary, dark);
    background-color: colors(secondary);
    text-align: center;
    line-height: 1;
    label {
      display: block;
      padding: 1em 0; } }
  &_body {
    max-height: 0;
    overflow: hidden;
    transition: all .5s;
    &_name, &_close {
      @include pxrem(1.8);
      margin: 0;
      padding: 1em 0;
      border: 1px solid colors(secondary, dark);
      border-top: 0;
      background-color: colors(mono, w);
      text-align: center;
      opacity: .9;
      line-height: 1; } }
  &_chk {
    display: none;
    &:checked ~ .dropdown_title {
      opacity: .7; }
    &:checked ~ .dropdown_body {
      max-height: 1000px; } } }

@keyframes anime-dropdown-body {
  0% {
    opacity: 0; }
  100% {
    opacity: .95; } }

@keyframes anime-dropdown-title {
  0% {
    opacity: 1; }
  100% {
    opacity: .7; } }
