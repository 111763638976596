.u {
  &_mt {
    &-m {
      margin-top: 30px; }
    &-s {
      margin-top: 10px; } }
  &_mb {
    &-m {
      margin-bottom: 30px; } }
  &_mlr {
    &-m {
      margin-right: 10px;
      margin-left: 10px; } } }

.clear {
  clear: both; }
