@charset "UTF-8";
// ==========================================================================
// Header
// ==========================================================================
.header {
  @include clearfix;
  height: 38px;
  width: 100%;
  background-color: colors(mono, w);
  &_logo {
    height: 38px;
    float: left;
    margin: 0 auto 0 5px;
    padding: 0;
    line-height: 38px;
    picture, img {
      vertical-align: middle; } }
  button {
    @include pxrem(1.3);
    float: right;
    appearance: none;
    display: block;
    height: 33px;
    margin: 2.5px 5px;
    padding: 0 1em;
    border: 2px solid colors(primary, dark);
    border-bottom: 3px solid colors(primary, dark);
    border-radius: 13px;
    background-color: colors(mono, w);
    color: colors(mono, k);
    text-align: center;
    cursor: pointer; } }
