@charset "UTF-8";
// ==========================================================================
// Main
// ==========================================================================
%heading {
  @include pxrem(1.8);
  padding: 1em 0;
  background-color: colors(secondary);
  text-align: center;
  line-height: 1.3; }

.wrapper {
  width: 100%; }
.pageTitle {
  @include pxrem(2);
  width: 100%;
  padding: .5em 0;
  margin: 0;
  background-color: colors(primary, dark);
  color: colors(mono, w);
  text-align: center; }

.main {
  position: relative;
  width: 100%;
  margin: auto;
  padding: 10px 0;
  background-color: colors(secondary, light);
  color: colors(text); }

.content {
  overflow: hidden;
  position: relative;
  width: 100%;
  &_title {
    @include pxrem(1.5);
    width: 100%;
    margin: 0;
    text-align: center;
    font-weight: bold;
    &-step {
      @extend .content_title;
      padding-left: 80px;
      text-align: left; } } }

.step {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &_title {
    order: 1;
    margin-left: 2.5%;
    text-align: left; }
  &_item {
    @include pxrem(1.2);
    order: 0;
    flex-shrink: 0;
    height: 66px;
    width: 66px;
    margin-left: 2.5%;
    border-radius: 100%;
    background-color: colors(mono, w);
    color: colors(secondary, dark);
    font-weight: bold;
    text-align: center;
    line-height: 1.3;
    p {
      display: table-cell;
      height: 66px;
      width: 66px;
      margin: 0;
      padding: 0;
      text-align: center;
      vertical-align: middle; }
    &_now {
      @include pxrem(2); } } }
