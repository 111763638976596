@charset "UTF-8";
// ==========================================================================
// Base
// ==========================================================================
html {
  position: relative;
  font-size: 62.5%;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto; }

body {
  @include pxrem(1.4);
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: colors(mono, w);
  color: colors(text);
  line-height: 1.777; }

*, *::before, *::after {
  box-sizing: border-box; }

img {
  max-width: 100%;
  vertical-align: middle; }



// ==========================================================================
// Font family
// ==========================================================================
body, tooltip, popover {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif; }

// 下記monospaceは、初期値です
code, kbd, pre, samp {
  font-family: monospace; }
