@charset "UTF-8";
// ==========================================================================
// Input data
// ==========================================================================
.workQuestion {
  width: 90%;
  margin: 0 auto 40px;
  &:last-child {
    margin-bottom: 0; }
  &_title {
    @include pxrem(1.2);
    position: relative;
    display: block;
    margin: 0 0 0 15px;
    line-height: 1.7;
    text-align: justify;
    text-justify: inter-ideograph;
    &:before {
      position: absolute;
      display: block;
      top: 3px;
      left: -15px;
      content: ' ';
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: colors(text); } }
  &_body {
    margin: 10px 0;
    .label-radio {
      @include pxrem(1.7); } } }
