@charset "UTF-8";
// ==========================================================================
// Form
// ==========================================================================
.form {
  @include pxrem(1.8);
  display: block;
  line-height: 1;
  &_item {
    width: 100%;
    padding-bottom: 11px;
    &:last-child {
      padding-bottom: 0; }
    &_title {
      @extend %heading; }
    &_body {
      margin: 11px 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle; } } }

.label {
  &-left {
    padding-right: 15px; }
  &-radio {
    margin-right: 2em;
    font-weight: bold;
    vertical-align: bottom;
    cursor: pointer;
    &:last-child {
      margin-right: 0; } } }

%setting {
  @include pxrem(1.8);
  height: 45px;
  border: 1px solid colors(secondary);
  border-radius: 7px;
  background-color: colors(mono, w);
  color: colors(text);
  font-weight: bold;
  vertical-align: middle;
  outline: 0; }

.input {
  @extend %setting;
  padding: 0 1em;
  &-middle {
    width: 355px; }
  &-expand {
    width: 100%; }
  &-searchBar {
    border: 1px solid #b28146; } }

.select {
  @extend %setting;
  appearance: none;
  background: colors(mono, w) url('/images/sp/common/select_arrow.png') no-repeat;
  text-align: center;
  cursor: pointer;
  &:focus {
    outline: 0; }
  &-short {
    width: 38%;
    padding: 0 36px 0 1em;
    background-position: right center; }
  &-middle {
    width: 77%;
    padding: 0 36px 0 1em;
    background-position: right center; }
  &-expand {
    width: 100%;
    padding: 0 36px 0 1em;
    background-position: right center; }
  &-shift {
    margin-left: 1em; } }

select::-ms-expand {
  display: none; }

.radio {
  display: inline-block;
  appearance: none;
  -moz-appearance: radio;
  height: 22px;
  width: 22px;
  border: 1px solid colors(secondary);
  border-radius: 100%;
  background-color: colors(mono, w);
  vertical-align: middle;
  cursor: pointer;
  &:checked {
    background-color: colors(secondary);
    box-shadow:  0 0 0 5px colors(mono, w) inset; }
  &:focus {
    outline: 0; }
  &[type="radio"] {
    margin: -2px .3em 0 0; } }

.searchBar {
  display: flex;
  flex-wrap: nowrap;
  padding: 7.2%;
  margin-bottom: 10px;
  .input-searchBar {
    flex-grow: 1;
    width: 10px;
    margin-right: 5px; }
  .btn-searchBar {
    @extend %setting;
    flex-shrink: 0;
    padding: 0 .5em;
    border: 1px solid #b28146;
    background-color: #c79f62;
    color: colors(mono, w);
    font-weight: normal; } }
