@charset "UTF-8";
// ==========================================================================
// Password
// ==========================================================================
.password {
  padding: 1em;
  text-align: justify;
  text-justify: inter-ideograph;
  &_text {
    @include pxrem(1.6);
    font-weight: bold;
    margin-bottom: 30px; }
  &_pass {
    @include pxrem(3.5);
    font-weight: normal;
    text-align: center; }
  &_merit {
    @include pxrem(1.5);
    margin-top: 30px;
    text-align: left; } }
