@charset "UTF-8";
// ==========================================================================
// Mypage
// ==========================================================================
.myLevel {
  text-align: center; }

.myComment {
  margin-top: 20px;
  text-align: center;
  &_body {
    @include pxrem(1.6);
    position: relative;
    margin: 30px 10px 0 10px;
    padding: .8em 1em;
    border: 2px solid colors(secondary);
    border-radius: 15px;
    background-color: colors(secondary, exlight);
    color: colors(mono, k);
    text-align: justify;
    text-justify: inter-ideograph;
    font-weight: bold;
    line-height: 1.7;
    &:before {
      position: absolute;
      top: -48px;
      left: 50%;
      margin-left: -14px;
      content: ' ';
      height: 24px;
      width: 24px;
      border-top: 24px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 24px solid colors(secondary);
      border-left: 14px solid transparent; }
    &:after {
      position: absolute;
      top: -43px;
      left: 50%;
      margin-left: -14px;
      content: ' ';
      height: 24px;
      width: 24px;
      border-top: 24px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 24px solid colors(secondary, exlight);
      border-left: 14px solid transparent; } } }

.myMap {
  margin-top: 40px;
  &_title {
    @extend %heading;
    margin: 0; }
  &_detail {
    padding: 10px;
    &_total {
      &_text {
        @include pxrem(2);
        margin: 0;
        padding: .2em;
        border-bottom: 2px solid colors(secondary);
        text-align: center;
        font-weight: bold; } }
    &_average {
      margin-top: 1em;
      &_text {
        @include pxrem(1.6);
        margin: 0;
        padding: .3em;
        text-align: center; } } }
  picture, img {
    max-width: 100%; }
  &_comment {
    @include pxrem(1.6);
    margin: 10px;
    padding: .8em 1em;
    border: 2px solid colors(secondary);
    border-radius: 15px;
    background-color: colors(secondary, exlight);
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    em {
      color: #ce2f51;
      font-style: normal; } } }

.myReaching {
  margin-top: 2.6em;
  &_title {
    @extend %heading; }
  &_list {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    margin: 1em;
    &_item {
      margin: 1%;
      &_text {
        @include pxrem(1.3);
        margin: 0;
        padding: 0;
        text-align: center; } } } }
